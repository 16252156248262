<template>
  <!-- 第三方登录 -->
  <div class="login">
    <!-- 登录主体 -->
    <div class="login-third-wrapper flex-align">
      <div class="container">
        <div class="form-section">
          <h2 class="welcome">欢迎登录</h2>
          <!-- <div class="form"></div> -->
          <!-- :rules="rules" -->
          <div class="tabs tc">
            <span class="tab" :class="{'active-tab':!activeForm }" @click="toggle">密码登录</span>
            <i class="gap">|</i>
            <span class="tab" :class="{'active-tab':activeForm}" @click="toggle">验证码登录</span>
          </div>

          <!-- 账号密码登录 start -->
          <el-form v-if="!activeForm" key="pwdForm" :rules="pwdFormRule" :model="pwdForm" status-icon ref="pwdForm"
            label-width="80px" class="login-form" @submit.native.prevent>
            <el-form-item label="用户名：" prop="username">
              <el-input type="text" v-model="pwdForm.username" maxlength="11" autocomplete="off"
                placeholder="请输入手机号码或用户名" id="userName"></el-input>
            </el-form-item>
            <el-form-item :label="`密码：`" prop="password">
              <el-input type="password" id="pwd" v-model="pwdForm.password" autocomplete="off" placeholder="请输入登录密码">
              </el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="verifyCode" class="el-form-code-item">
              <el-input v-model="pwdForm.verifyCode" id="verifycode" placeholder="请输入验证码"></el-input>
              <div class="validate-code" @click="refreshCode">
                <SIdentify :identifyCode="identifyCode"></SIdentify>
              </div>
            </el-form-item>
            <!-- <el-form-item label prop="type" class="summary">
            <el-checkbox-group v-model="pwdForm.type">
              <el-checkbox label="记住密码" name="type"></el-checkbox>
              <el-link type="primary" @click="open()">忘记密码？</el-link>
            </el-checkbox-group>
          </el-form-item> -->
            <el-form-item class="btn-box" label-width="0">
              <el-button type="primary" id="submit" @click="submitForm('pwdForm')" class="login-btn">{{loginText}}
              </el-button>
              <!-- <el-button @click="resetForm('pwdForm')">重置</el-button> -->
            </el-form-item>
          </el-form>
          <!-- 账号密码登录 end -->


          <!-- 手机号登录 start -->
          <el-form v-else key="verifyForm" :rules="verifyFormRule" :model="verifyForm" status-icon ref="verifyForm"
            label-width="70px" class="login-form" @submit.native.prevent hide-required-asterisk>
            <el-form-item label="手机号：" prop="phone">
              <el-input type="text" v-model="verifyForm.phone" maxlength="11" autocomplete="off" id="phone"
                placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="verifyCode" class="el-form-code-item">
              <el-input v-model="verifyForm.verifyCode" id="tel-phone" placeholder="请输入手机验证码">
              </el-input>
              <el-button class="getVerfiyCode" @click="getCode" v-if="showCode">获取验证码</el-button>
              <el-button class="getVerfiyCode" @click="getCode" v-else>{{`重新发送(${count}s)`}}</el-button>
            </el-form-item>

            <el-form-item class="btn-box" label-width="0">
              <el-button type="primary" id="tel-phone" @click="submitForm('verifyForm')" class="login-btn">
                {{loginText}}
              </el-button>
            </el-form-item>
          </el-form>
          <!-- 手机号登录 end -->

          <!-- 其他登录方式 start -->
          <div class="other-login flex-align-center">
            <div class="item">
              <!-- <p class="other-login csp" @click="toBind">使用银川智慧教育平台账号登录</p> -->
              <p class="other-login csp" @click="toBindYcDingDing">使用宁教云账号登录</p>
              <!-- <router-link to="/login/bindAccount" class="other-login">使用银川智慧教育平台账号登录</router-link> -->
              <!-- <a href="/login/bindAccount" class="other-login">使用银川智慧教育平台账号登录</a> -->
            </div>
          </div>
          <!-- 其他登录方式 end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SIdentify from "./component/identify";
  export default {
    components: {
      SIdentify,
    },
    data() {
      // 验证手机号格式
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else if (!/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/.test(value)) {
          return callback(new Error('手机号格式错误'));
        } else {
          return callback();
        }
      };
      // 验证 手机验证码
      var checkCode = (rule, value, callback) => {
        // console.log(value)
        if (!value) {
          return callback(new Error('验证码不能为空'));
        } else {
          let params = {
            phone: this.verifyForm.phone,
            code: String(value)
          }
          this.$Api.Login.checkCode(params).then((resData) => {
            //  console.log(resData)
            if (resData.data.result) {
              return callback();
            } else {
              this.$message({
                message: resData.data.msg,
                type: 'error'
              });
              return callback(new Error('验证码错误'));
            }
          })
        }
      };
      // 验证用户名
      var checkUserName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('账号不能为空'));
        } else {
          return callback();
        }
      };
      // 验证密码
      var checkPwd = (rule, value, callback) => {
        // console.log(value)
        if (!value) {
          return callback(new Error('密码不能为空'));
        } else {
          return callback();
        }
      };
      // 验证 密码登录 验证码
      var checkVerifyCode = (rule, value, callback) => {
        return callback();

        /**请勿删除 需要 验证码验证时 使用以下代码 */
        // if(!value) {
        //   return callback(new Error('验证码不能为空'));
        // } else if (value != this.identifyCode){
        //   return callback(new Error('验证码错误'));
        // } else {
        //   return callback();
        // }
      };

      return {
        activeForm: false, //当前表单 [false 用户名密码表单 / true 手机验证码表单]
        identifyCode: "",
        identifyRange: "1234567890", //验证码选取范围
        loginText: '登录', //登录文字
        loginFlag: true, //登录状态
        pwdForm: { //账号密码登录表单项
          password: "",
          username: "",
          verifyCode: "",
          type: []
        },
        pwdFormRule: { //账号密码登录 验证规则
          username: [{
            required: true,
            trigger: "blur",
            validator: checkUserName
          }],
          password: [{
            required: true,
            trigger: "blur",
            validator: checkPwd
          }],
          verifyCode: [{
            required: false,
            trigger: "blur",
            validator: checkVerifyCode
          }],
        },
        verifyForm: { //手机号登录表单项
          phone: "",
          verifyCode: "",
        },
        verifyFormRule: { //手机号登录 验证规则
          phone: [{
            required: true,
            trigger: "blur",
            validator: checkPhone
          }],
          verifyCode: [{
            required: true,
            message: "请输入验证码",
            trigger: "blur",
            validator: checkCode
          }],
        },
        showCode: true, //验证码按钮显隐状态
        count: 0, //倒计时 - 计数器
        timer: "", //倒计时 - 计时器
        dialogVisible: false, //弹窗状态

      };
    },

    methods: {
      async getPrecisionTeach() {
        let params = {
          id: 2
        }
        let resData = await this.$Api.Home.getAuthKey(params);
        // console.log(resData);
        window.location.href = `${resData.data.url}&ticket=${resData.data.ticket}`;
      },
      // 切换登录方式
      toggle() {
        this.activeForm = !this.activeForm;
        this.activeForm ? this.resetForm('pwdForm') : this.resetForm('verifyForm')
      },
      // 去绑定账户（第三方登录）
      async toBind() {
        let params = {
          type: 'ycThird',
          path: `https://${window.location.host}/login/bindAccount`
        }
        let resData = await this.$Api.Home.loginThird(params)
        // console.log(resData);
        if (resData.code == 200 && resData.data) {
          window.location.href = resData.data.redirectUrl
        }
      },
      // 去绑定账户（第三方登录）
      async toBindYcDingDing() {
        let params = {
          type: 'ycThird',
          path: `https://${window.location.host}/login/bindYcDingDingAccount`
        }
        let resData = await this.$Api.Home.loginThird(params)
        // console.log(resData);
        if (resData.code == 200 && resData.data) {
          window.location.href = resData.data.redirectUrl
        }
      },
      // 去忘记密码页面
      open() {
        this.$router.push({
          name: 'forgetpwd'
        })
      },
      // 刷新用户密码登录验证码
      refreshCode() {
        this.identifyCode = "";
        this.makeCode(this.identifyRange, 4);
      },
      // 数字验证码获取
      makeCode(o, l) {
        for (let i = 0; i < l; i++) {
          this.identifyCode += this.identifyRange[Math.floor(Math.random() * (this.identifyRange.length - 0) + 0)];
        }
      },
      // 获取验证码
      async getCode() {
        if (!/^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/.test(this.verifyForm
            .phone)) {
          this.$message({
            message: '手机号格式错误',
            type: 'error'
          });
          return
        }
        this.countDown();
        let params = {
          phone: this.verifyForm.phone
        }
        let resData = await this.$Api.Login.getVerifyCode(params)
        // console.log(resData);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message({
            message: '验证码发送成功',
            type: 'success'
          });
        } else {
          this.$message({
            message: resData.msg,
            type: 'error'
          });
        }
      },
      // 倒计时
      countDown() {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.showCode = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.showCode = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      },
      // 点击验证表单
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.commitForm(formName);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 表单提交
      async commitForm(formName) {
        if (!this.loginFlag) return
        this.loginText = "登录中..."
        this.loginFlag = false;

        let params = {};
        let resData = '';

        // 用户名、密码登录
        if (formName === 'pwdForm') {
          params = {
            username: this[formName].username,
            password: this[formName].password
          }
          resData = await this.$Api.Home.login(params);
        }

        //手机验证码登录 
        if (formName === 'verifyForm') {
          params = {
            phone: this[formName].phone,
            code: this[formName].verifyCode
          }
          resData = await this.$Api.Login.loginByPhone(params);
        }

        this.loginFlag = true;
        this.loginText = "登录"
        if (resData.code === 200 && resData.data.access_token) {
          // sessionStorage.token = resData.data.access_token;
          this.$store.commit('getToken', resData.data.access_token);
          this.getPrecisionTeach();
          // this.getUserinfo();
        } else {
          this.loginFlag = true;
          this.$notify.error({
            title: "错误",
            message: resData.msg,
          });
        }
      },
      // 获取文件预览token 
      async getFileToken() {
        let resData = await this.$Api.Resources.getFileToken();
        // console.log(resData);
        // sessionStorage.setItem('fileToken', resData.data.token);
        this.$store.commit('getFileToken', resData.data.token);
      },
      // 获取用户信息
      async getUserinfo() {
        let resData = await this.$Api.Home.userInfo();
        if (JSON.stringify(resData.data) == {}) {
          this.$message({
            message: '获取个人信息失败，请联系管理员',
            type: 'error'
          });
          sessionStorage.clear();
          return;
        }
        // sessionStorage.userinfo = JSON.stringify(resData.data);
        this.$store.commit('getUserInfo', resData.data);
        this.getFileToken();
        this.getRoles();
      },
      // 获取用户身份
      async getRoles() {
        let resData = await this.$Api.Home.getroles();
        if (resData.data.length <= 0) {
          this.$message({
            message: '获取个人信息失败，请联系管理员',
            type: 'error'
          });
          sessionStorage.clear();
          return;
        }
        // sessionStorage.role = resData.data[0].value;
        // sessionStorage.roleId = resData.data[0].id;
        // sessionStorage.rolelist = JSON.stringify(resData.data);
        this.$store.commit('getUserRoleList', resData.data)
        this.$store.commit('getRoleId', resData.data[0].id);
        this.$store.commit('getRole', resData.data[0].value);

        this.$notify({
          title: "成功",
          message: "登陆成功",
          type: "success"
        });

        this.$router.push("/home");
      },

      // 按回车登录
      enterLogin() {
        let key = window.event.keyCode
        if (window.event === undefined) {
          key = e.keyCode
        } else {
          key = window.event.keyCode
        }
        if (key === 13 && !this.activeForm) {
          this.submitForm('pwdForm')
        } else if (key === 13 && this.activeForm) {
          this.submitForm('verifyForm');
        } else {
          return;
        }
      },
      //重置输入框中的数据
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    },
    created() {
      if (sessionStorage.token !== undefined && sessionStorage.userinfo !== {} && sessionStorage.rolelist !== []) {
        return this.$router.push("/home");
      }
    },
    mounted() {
      this.identifyCode = "";
      this.makeCode(this.identifyRange, 4);
      window.addEventListener('keyup', this.enterLogin, true)
    },
    destroyed() {
      this.activeForm = false;
      window.removeEventListener('keyup', this.enterLogin, true);
    },
    // deactivated(){
    //   this.activeForm = false;
    //   window.removeEventListener('keyup',this.enterLogin, true);
    // }
  };
</script>

<style lang="less" scoped>
  @import "./less/login_third.less";
</style>